import { MONTH } from "../constains";

export function genarateMonthCode() {
  let monthCode = {};
  MONTH.forEach((m, index) => {
    monthCode[index] = {
      id: index,
      month: m
    };
  });
  return monthCode;
}
export const numberWithCommas = valNumber => {
  const regex = /^\d.*$/;
  const result = regex.test(valNumber);
  let value;
  if (result) {
    value = parseInt(valNumber)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return value;
  }
  return valNumber;
};
