import produce from "immer";
import get from "lodash.get";
import { VERIFY_PHONE_FINISH, VERIFY_OTP_FINISH } from "./constants";
export const initialState = {
  verifyPhone: {
    isVerify: true,
    errorMessage: ""
  },
  verifyOTP: {
    isVerify: true,
    errorMessage: ""
  }
};
const forgotPassReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case VERIFY_PHONE_FINISH:
        const { success, errors } = action.payload;
        draft.verifyPhone.isVerify = success;
        draft.verifyPhone.errorMessage = get(errors, ["0"], null);
        break;
      case VERIFY_OTP_FINISH:
        draft.verifyOTP.isVerify = action.payload.success;
        draft.verifyOTP.errorMessage = get(action.payload.errors, ["0"], null);
        break;
      default:
        return state;
    }
  });
export default forgotPassReducer;
