import React from "react";
// import "./style.scss"
const Avatar = React.memo(props => {
  const { original_filename, url } = props.imgData || {};
  return (
    <img className="header-barge__avatar" src={url} alt={original_filename} />
  );
});

export default Avatar;
