import React from "react";
import "./style.scss";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const InputPassword = React.memo(props => {
  const {
    showPassword,
    handleChange,
    handleClickShowPassword,
    val,
    clssName,
    clssFeild,
    helperText,
    error,
    id,
    labelTxt,
    name
  } = props;

  return (
    <FormControl className={`feild-group feild-password ${clssName}`} fullWidth>
      <TextField
        id={`${id ? id : "password"}`}
        className={`${clssFeild ? clssFeild : ""}`}
        variant="outlined"
        error={error || false}
        type={showPassword ? "text" : "password"}
        label={`${labelTxt ? labelTxt : "Mật khẩu"}`}
        value={val}
        name={`${name ? name : id}`}
        onChange={handleChange}
        helperText={`${helperText ? helperText : ""}`}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={() => handleClickShowPassword(name)}>
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
        required={true}
      />
    </FormControl>
  );
});

export default InputPassword;
