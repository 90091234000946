import React from "react";
import "./style.scss";
import Header from "../Header";
import background from "../../assets/images/test.png";
const AppLayout = props => {
  const { children } = props;

  return (
    <div className="app-layout">
      <Header />
      <div className="app-layout__main container">
        <div className="app-layout__main__content">{children}</div>
      </div>
      <img id="app-background" src={background} alt="left" />
    </div>
  );
};

export default React.memo(AppLayout);
