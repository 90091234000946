import produce from "immer";
export const initialState = {
  isChangePassword: false
};
const informationReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case "OPEN_FORM_CHANGE_PASSWORD":
        const data = action.payload;
        draft.isChangePassword = data;
        break;
      default:
        return state;
    }
  });
export default informationReducer;
