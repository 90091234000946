import { reduxAction } from "../../store/action";
import { put, takeLatest, select } from "redux-saga/effects";
import requestApi from "../../apis/requestApi";
import {
  makeSelectConfirmPage,
  makeSelectConfirmRowsPerPage,
  makeSelectConfirmOrder,
  makeSelectConfirmKeyword,
  makeSelectUnconfirmPage,
  makeSelectUnconfirmRowsPerPage,
  makeSelectUnconfirmKeyword,
  makeSelectUnconfirmOrder
} from "./selectors";
import {
  CONFIRM_GET_LIST_CONFIRM,
  CONFIRM_GET_LIST_CONFIRM_SUCCESS,
  CONFIRM_GET_LIST_UNCONFIRM,
  CONFIRM_GET_LIST_UNCONFIRM_SUCCESS
} from "./constants";
import {
  COMMON_SHOW_LOADING,
  COMMON_HANDLE_ERROR,
  COMMON_HIDE_LOADING
} from "../../store/constants";

function* getConfirmSaga(action) {
  try {
    yield put(reduxAction(COMMON_SHOW_LOADING));
    const page = yield select(makeSelectConfirmPage) || 1;
    const rowsPerPage = yield select(makeSelectConfirmRowsPerPage) || 1;
    const { order, orderBy } = yield select(makeSelectConfirmOrder);
    const keyword = yield select(makeSelectConfirmKeyword);

    const res = yield requestApi.get(
      `/hrs/verified_employees?page=${page}&per_page=${rowsPerPage}${
        order ? "&direction=" + order : ""
      }${orderBy ? "&sort=" + orderBy : ""}${keyword && `&s=${keyword}`}`
    );
    const { data } = res;
    if (data) {
      yield put(reduxAction(CONFIRM_GET_LIST_CONFIRM_SUCCESS, data));
    }
  } catch (err) {
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put(reduxAction(COMMON_HIDE_LOADING));
  }
}

function* getUnconfirmSaga(action) {
  try {
    yield put(reduxAction(COMMON_SHOW_LOADING));
    const page = yield select(makeSelectUnconfirmPage) || 1;
    const rowsPerPage = yield select(makeSelectUnconfirmRowsPerPage) || 1;
    const { order, orderBy } = yield select(makeSelectUnconfirmOrder);
    const keyword = yield select(makeSelectUnconfirmKeyword);
    const res = yield requestApi.get(
      `/hrs/unverified_employees?page=${page}&per_page=${rowsPerPage}${
        order ? "&direction=" + order : ""
      }${orderBy ? "&sort=" + orderBy : ""}${keyword && `&s=${keyword}`}`
    );
    const { data } = res;
    if (data) {
      yield put(reduxAction(CONFIRM_GET_LIST_UNCONFIRM_SUCCESS, data));
    }
  } catch (err) {
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put(reduxAction(COMMON_HIDE_LOADING));
  }
}

export function* watchConfirm() {
  yield takeLatest(CONFIRM_GET_LIST_CONFIRM, getConfirmSaga);
  yield takeLatest(CONFIRM_GET_LIST_UNCONFIRM, getUnconfirmSaga);
}
