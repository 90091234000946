import "./style.scss";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
class DatePickerCustom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date()
    };
    this.myRef = React.createRef();
  }

  handleChange = date => {
    const { name } = this.props;
    this.props.handleChange({
      target: {
        name: name,
        value: date
      }
    });
  };

  render() {
    const {
      title,
      name,
      isMobile,
      value,
      error,
      className,
      showMonthYearPicker,
      dateFormat,
      labelError
    } = this.props;
    return (
      typeof window === "object" && (
        <div
          className={`date-picker-c ${className ? className : ""} ${
            error ? "date-picker-c--error" : ""
          } ${isMobile ? "m-b-25" : ""}`}>
          <span className="date-picker-c__title">{title}</span>
          <DatePicker
            // minDate={minDate ? minDate : ""}
            at="dd/MM/yyyy"
            name={name}
            selected={value ? new Date(value) : new Date()}
            onChange={this.handleChange}
            ref={this.myRef}
            className=""
            showMonthYearPicker={
              showMonthYearPicker ? showMonthYearPicker : false
            }
            dateFormat={dateFormat ? dateFormat : "dd/MM/yyyy"}
            onFocus={() => {
              // const { input = {} } = this.myRef.current;
              // let rect = input.getBoundingClientRect() || {};
              // setTimeout(() => {
              //   let datepicker = document.querySelector(
              //     ".react-datepicker-popper"
              //   );
              //   if (datepicker) {
              //     datepicker.style.top = rect.top + "px";
              //     datepicker.style.display = "inherit";
              //   }
              // }, 300);
            }}
          />
          {labelError ? (
            <span className="label-error error-item label-small m-t-5">
              {labelError}
            </span>
          ) : (
            ""
          )}
        </div>
      )
    );
  }
}

export default DatePickerCustom;
