export const LOCALE = "locale";
export const MONTH = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];
export const genderList = [
  {
    value: 0,
    label: "Nữ"
  },
  {
    value: 1,
    label: "Nam"
  }
];
export const workingTimeList = [
  {
    value: 1,
    label: "Dưới 6 tháng"
  },
  {
    value: 2,
    label: "Từ 6-12 tháng"
  },
  {
    value: 3,
    label: "1 năm đến 2 năm"
  },
  {
    value: 4,
    label: "Trên 2 năm"
  }
];
export const positionType = [
  {
    value: "0",
    label: "Nhân viên"
  },
  {
    value: "1",
    label: "Trưởng nhóm/trưởng phòng/Giám sát"
  },
  {
    value: "2",
    label: "Quản lý cấp cao (Phó Giám đốc, Giám đốc)"
  }
];
