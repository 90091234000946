import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import { reduxAction } from "../../../store/action";
import { EDIT_PROFILE_ME } from "../constants";
import FormContainer from "../../../components/FormContainer";
import FormContent from "../../../components/FormContent";
import RowDetail from "../../../components/RowDetail";
import SelectComponent from "../../../components/SelectComponent";
import InputComponent from "../../../components/InputComponent";
import TextAreaComponent from "../../../components/TextAreaComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import UploadComponent from "../../../components/UploadComponent";
import { genderList } from "../../../constains";
import useForm from "../../../hooks/useForm";
import "./style.scss";
import Vali from "../../../utils/validator/validate";
const InformationDetailUpdate = ({ handleEditProfile, data }) => {
  const dispatch = useDispatch();
  let schema = {};
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    handleSelect,
    handleUpload
  } = useForm(data, onEditProfileMe, schema, { isSameOrigin: true });
  const onEditProfileUser = useCallback(() => {
    handleEditProfile(false);
  }, []);
  function onEditProfileMe() {
    const cloneValues = { ...values };
    cloneValues["gender"] = values.gender.id;
    dispatch(reduxAction(EDIT_PROFILE_ME, cloneValues));
  }
  const { documents } = values || {};
  schema.full_name = new Vali(values.full_name).isRequire(
    "Tên bắt buộc phải nhập"
  );
  schema.phone_backup = new Vali(values.phone_backup)
    .numbers("Số điện thoại phải là số")
    .lengths(9, 10, "Số điện thoại phải từ 9 đến 10 ký tự");
  // schema.hr_role = new Vali(values.hr_role).isRequire(
  //   "Chức vụ không được bỏ trống"
  // );
  schema.email = new Vali(values.email)
    .isEmail("Email chưa đúng định dạng")
    .isRequire("Email là bắt buộc nhập");
  schema.email_backup = new Vali(values.email_backup).isEmail(
    "Email chưa đúng định dạng"
  );
  return (
    <>
      <FormContainer>
        <FormContent>
          <RowDetail title="Họ và tên" isTitle={true} clssName="custom-input">
            <InputComponent
              val={values.full_name ? values.full_name : ""}
              name="full_name"
              clssName="input-normal"
              handleChange={handleChange}
              helperText={errors.full_name ? errors.full_name : ""}
              error={errors.full_name ? true : false}
            />
          </RowDetail>
          <RowDetail title="Giới tính" isTitle={true} clssName="custom-input">
            <SelectComponent
              ranges={genderList}
              clssName="input-normal"
              val={values && values.gender ? values.gender.id : ""}
              name="gender"
              handleChange={handleSelect}
            />
          </RowDetail>
          <RowDetail
            title="Công ty"
            name="name"
            isTitle={true}
            clssName="custom-input"
            content={
              values.companies
                ? values.companies.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        - {item.name}
                        <br />
                      </React.Fragment>
                    );
                  })
                : ""
            }
          />
          <RowDetail title="Chức vụ" isTitle={true} clssName="custom-input">
            <InputComponent
              val={values.hr_role ? values.hr_role : ""}
              name="hr_role"
              handleChange={handleChange}
              clssName="input-normal"
              // helperText={errors.hr_role ? errors.hr_role : ""}
              // error={errors.hr_role ? true : false}
            />
          </RowDetail>
          <RowDetail title="Ảnh chân dung" clssName="custom-input">
            <UploadComponent
              type="onlyShow"
              className="--edit-personal upload--img only-show upload--horizontal"
              name="documents"
              txtBtnUpdate="Cập nhật"
              handleUpload={handleUpload}
              notShowPicture="false"
              files={documents && documents.url ? documents.url : ""}
              error={!!errors.documents}
              helperText={errors.documents ? errors.documents : ""}
            />
          </RowDetail>
        </FormContent>
        <FormContent>
          <RowDetail
            title="Số điện thoại 1"
            content={values.phone ? values.phone : "_"}
            isTitle={true}
          />
          <RowDetail
            title="Số điện thoại 2"
            isTitle={true}
            clssName="custom-input">
            <InputComponent
              val={values.phone_backup ? values.phone_backup : ""}
              name="phone_backup"
              handleChange={handleChange}
              helperText={errors.phone_backup ? errors.phone_backup : ""}
              error={errors.phone_backup ? true : false}
              clssName="input-normal"
            />
          </RowDetail>
          <RowDetail title="Email 1" isTitle={true} clssName="custom-input">
            <InputComponent
              val={values.email ? values.email : ""}
              name="email"
              handleChange={handleChange}
              helperText={errors.email ? errors.email : ""}
              error={errors.email ? true : false}
              clssName="input-normal"
            />
          </RowDetail>
          <RowDetail title="Email 2" isTitle={true} clssName="custom-input">
            <InputComponent
              val={values.email_backup ? values.email_backup : ""}
              name="email_backup"
              handleChange={handleChange}
              helperText={errors.email_backup ? errors.email_backup : ""}
              error={errors.email_backup ? true : false}
              clssName="input-normal"
            />
          </RowDetail>
          <RowDetail
            title="Địa chỉ làm việc"
            name="name"
            isTitle={true}
            clssName="custom-input">
            <TextAreaComponent
              val={values.working_address ? values.working_address : ""}
              name="working_address"
              handleChange={handleChange}
            />
          </RowDetail>
        </FormContent>
      </FormContainer>
      <p className="feild-error">
        {errors && errors.isSameOrigin ? errors.isSameOrigin : ""}
      </p>
      <ButtonComponent
        className="btn btn--white--c1 m-l-5"
        content="Hủy"
        onClick={onEditProfileUser}
      />
      <ButtonComponent
        className="btn btn--yellow--c1 m-l-5"
        content="Lưu"
        onClick={handleSubmit}
      />
    </>
  );
};

export default React.memo(InformationDetailUpdate);
