import { toast } from "react-toastify";
import { TOAST_TYPE } from "./constants";
export const toastMessage = (type = TOAST_TYPE.INFO, message) => {
  if (type === TOAST_TYPE.CUSTOM) {
    toast(message, {
      position: toast.POSITION.BOTTOM_RIGHT,
      className: "toast-custom"
    });
  } else {
    toast[type](message, {
      position: toast.POSITION.BOTTOM_RIGHT
    });
  }
};

export const getLocalStorage = (type = "") => {
  return window.localStorage.getItem(type);
};

export const setLocalStorage = (type = "", value = "") => {
  window.localStorage.setItem(type, value);
};

export const removeLocalStorage = (type = "") => {
  window.localStorage.removeItem(type);
};
