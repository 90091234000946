import { useEffect, useState } from "react";
let DEVICE_TYPE = {
  MOBILE: "mobile",
  TABLET: "tablet"
};

export const useDevice = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const checkDivice = width => {
    let type = "";

    if (width < 768) {
      type = DEVICE_TYPE.MOBILE;
    } else if (width >= 768 && width <= 1024) {
      type = DEVICE_TYPE.TABLET;
    }
    return {
      isMobile: type === DEVICE_TYPE.MOBILE,
      isTablet: type === DEVICE_TYPE.TABLET
    };
  };
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return checkDivice(width);
};
