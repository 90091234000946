import React from "react";
import "./style.scss";

import TextField from "@material-ui/core/TextField";

const InputNormal = React.memo(props => {
  const {
    value = "",
    name,
    error,
    onChange,
    disabled,
    className,
    placeholder
  } = props;

  return (
    <div className="input-normal">
      <TextField
        className={className}
        value={value}
        name={name}
        error={error}
        onChange={onChange}
        margin="normal"
        variant="outlined"
        disabled={disabled}
        placeholder={placeholder}
      />
    </div>
  );
});

export default InputNormal;
