import React, { useState, useCallback } from "react";
import FormContainer from "../../../components/FormContainer";
import FormContent from "../../../components/FormContent";
import RowDetail from "../../../components/RowDetail";
import ModalCustom from "../../../components/ModalCustom";
import defaultImg from "../../../assets/images/default-user.png";
const InformationDetail = props => {
  let {
    companies,
    documents,
    email,
    email_backup,
    full_name,
    gender,
    hr_role,
    identity,
    phone,
    phone_backup,
    working_address
  } = props.data || {};
  const [isViewMoreImage, setIsViewMoreImage] = useState(false);
  const openModalImage = useCallback(value => {
    setIsViewMoreImage(value);
  });
  return (
    <>
      <FormContainer>
        <FormContent>
          <RowDetail
            title="Mã"
            content={identity ? identity : "_"}
            name="name"
            isTitle={true}
          />
          <RowDetail
            title="Họ và tên"
            content={full_name ? full_name : "_"}
            name="name"
            isTitle={true}
          />
          <RowDetail
            title="Giới tính"
            content={gender && gender.name ? gender.name : "_"}
            name="name"
            isTitle={true}
          />
          <RowDetail
            title="Công ty"
            content={
              companies
                ? companies.map((item, index) => {
                    return (
                      <React.Fragment key={index}>
                        {item.name}
                        <br />
                      </React.Fragment>
                    );
                  })
                : ""
            }
            name="name"
            isTitle={true}
          />
          <RowDetail
            title="Chức vụ"
            content={hr_role ? hr_role : "_"}
            name="name"
            isTitle={true}
          />
          <RowDetail
            title="Ảnh chân dung"
            content={
              <img
                alt="portrait"
                className="modal-image"
                src={documents ? documents.url : defaultImg}
                onClick={openModalImage}
              />
            }
          />
        </FormContent>
        <FormContent>
          <RowDetail
            title="Số điện thoại 1"
            content={phone ? phone : "_"}
            name="name"
            isTitle={true}
          />
          <RowDetail
            title="Số điện thoại 2"
            content={phone_backup ? phone_backup : "_"}
            name="name"
            isTitle={true}
          />
          <RowDetail
            title="Email 1"
            content={email ? email : "_"}
            name="name"
            isTitle={true}
          />
          <RowDetail
            title="Email 2"
            content={email_backup ? email_backup : "_"}
            name="name"
            isTitle={true}
          />
          <RowDetail
            title="Địa chỉ làm việc"
            content={working_address ? working_address : "_"}
            name="name"
            isTitle={true}
          />
        </FormContent>
      </FormContainer>
      {isViewMoreImage ? (
        <ModalCustom
          openModel={isViewMoreImage}
          info={{ id: "coin-modal", title: "" }}
          handleClose={() => openModalImage(false)}
          maxWidthDialog="md">
          <img
            alt="portrait"
            className="modal-image"
            src={documents ? documents.url : defaultImg}
          />
        </ModalCustom>
      ) : (
        ""
      )}
    </>
  );
};

export default React.memo(InformationDetail);
