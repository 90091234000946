export const localStore = {
  ACCESS_TOKEN: "access-token",
  CLIENT: "client",
  UID: "uid",
  EXPIRY: "expiry",
  TOKEN_TYPE: "token_type"
};
export const setLocalStore = header => {
  localStorage.setItem(localStore.ACCESS_TOKEN, header["access-token"]);
  localStorage.setItem(localStore.CLIENT, header.client);
  localStorage.setItem(localStore.UID, header.uid);
  localStorage.setItem(localStore.EXPIRY, header.expiry);
  localStorage.setItem(localStore.TOKEN_TYPE, header["token-type"]);
};
export const removeLocalStore = () => {
  localStorage.removeItem(localStore.ACCESS_TOKEN);
  localStorage.removeItem(localStore.CLIENT);
  localStorage.removeItem(localStore.UID);
  localStorage.removeItem(localStore.EXPIRY);
  localStorage.removeItem(localStore.TOKEN_TYPE);
};
export const setHeader = () => {
  let headers = {};
  headers["Content-Type"] = "application/json";
  headers["accept"] = "application/json";
  const token = localStorage.getItem(localStore.ACCESS_TOKEN);
  if (token) {
    headers[localStore.ACCESS_TOKEN] = token;
    headers[localStore.UID] = localStorage.getItem(localStore.UID);
    headers[localStore.TOKEN_TYPE] = localStorage.getItem(
      localStore.TOKEN_TYPE
    );
    headers[localStore.EXPIRY] = localStorage.getItem(localStore.EXPIRY);
    headers[localStore.CLIENT] = localStorage.getItem(localStore.CLIENT);
  }
  return headers;
};
export const getToken = () => {
  return localStorage.getItem(localStore.ACCESS_TOKEN);
};
