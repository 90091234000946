import produce from "immer";
import { COMMON_SHOW_LOADING, COMMON_HIDE_LOADING } from "../constants";

export const initialState = {
  showLoading: false
};
const layoutReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON_SHOW_LOADING:
        draft.showLoading = true;
        break;
      case COMMON_HIDE_LOADING:
        draft.showLoading = false;
        break;
      default:
        return state;
    }
  });
export default layoutReducer;
