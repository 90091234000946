import produce from "immer";

import { GET_EMPLOYEE_DETAIL_SUCCESS } from "./constants";
export const initialState = {
  employee: {}
};
const employeeDetailReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case GET_EMPLOYEE_DETAIL_SUCCESS:
        draft.employee = action.payload;
        break;
      default:
        return state;
    }
  });
export default employeeDetailReducer;
