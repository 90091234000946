import React, { useState, useCallback } from "react";
import InputPassword from "../InputPassword";
import ButtonComponent from "../ButtonComponent";
import FormContainer from "../FormContainer";
import FormContent from "../FormContent";
import useForm from "../../hooks/useForm";
import Vali from "../../utils/validator/validate";
import { reduxAction } from "../../store/action";
import { useDispatch } from "react-redux";
import { OPEN_FORM_CHANGE_PASSWORD } from "../../containers/Information/constants";
const ChangePassword = React.memo(props => {
  const dispatch = useDispatch();
  const [isShowPassword, setShowPassword] = useState({});
  let schema = {};
  const onChangePassword = () => {
    dispatch(reduxAction("CHANGE_PASSWORD", values));
  };
  const { errors, values, handleChange, handleSubmit } = useForm(
    {},
    onChangePassword,
    schema,
    { isSameOrigin: false }
  );
  schema.current_password = new Vali(values.current_password).isRequire(
    "Vui lòng nhập mật khẩu cũ"
  );
  schema.password = new Vali(values.password)
    .isRequire("Vui lòng nhập mật khẩu mới")
    .min(6, "Mật khẩu mới quá ngắn (tối thiểu 6 ký tự)")
    .isSamePass(
      values.password_confirmation,
      "Mật khẩu mới và xác nhận mật khẩu mới phải giống nhau"
    );
  schema.password_confirmation = new Vali(values.password_confirmation)
    .isRequire("Vui lòng nhập xác nhận mật khẩu mới")
    .min(6, "Xác nhận mật khẩu mới quá ngắn (tối thiểu 6 ký tự)")
    .isSamePass(
      values.password,
      "Mật khẩu mới và xác nhận mật khẩu mới phải giống nhau"
    );
  const onToggleShowPassword = name => {
    setShowPassword(previous => {
      return {
        ...previous,
        [name]: !previous[name]
      };
    });
  };
  const onCloseChangePassword = useCallback(param => {
    dispatch(reduxAction(OPEN_FORM_CHANGE_PASSWORD, param));
  });
  const { current_password, password, password_confirmation } = values || {};
  return (
    <div className="change-password">
      <FormContainer>
        <FormContent>
          <InputPassword
            id="password"
            name="current_password"
            clssName="m-t-20"
            clssFeild="wrapp-input"
            labelTxt="Mật khẩu hiện tại"
            showPassword={isShowPassword.current_password}
            val={current_password ? current_password : ""}
            handleChange={handleChange}
            handleClickShowPassword={onToggleShowPassword}
            error={!!errors.current_password}
            helperText={errors.current_password ? errors.current_password : ""}
          />
          <InputPassword
            id="password"
            name="password"
            clssName="m-t-20"
            clssFeild="wrapp-input"
            labelTxt="Mật khẩu mới"
            showPassword={isShowPassword.password}
            val={password ? password : ""}
            handleChange={handleChange}
            handleClickShowPassword={onToggleShowPassword}
            error={!!errors.password}
            helperText={errors.password ? errors.password : ""}
          />
          <InputPassword
            id="password"
            name="password_confirmation"
            clssName="m-t-20"
            clssFeild="wrapp-input"
            labelTxt="Xác nhận mật khẩu mới"
            showPassword={isShowPassword.password_confirmation}
            val={password_confirmation ? password_confirmation : ""}
            handleChange={handleChange}
            handleClickShowPassword={onToggleShowPassword}
            error={!!errors.password_confirmation}
            helperText={
              errors.password_confirmation ? errors.password_confirmation : ""
            }
          />
        </FormContent>
      </FormContainer>
      <FormContent>
        <p className="feild-error">
          {errors && errors.isNotEdit ? errors.isNotEdit : ""}
        </p>
      </FormContent>
      <ButtonComponent
        onClick={() => onCloseChangePassword(false)}
        className="btn btn btn--white--c1 m-l-5"
        content="Huỷ"
      />
      <ButtonComponent
        onClick={handleSubmit}
        className="btn btn btn--yellow--c1 m-l-5"
        content="Lưu"
      />
    </div>
  );
});

export default ChangePassword;
