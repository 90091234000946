import React from "react";
import { headerList } from "./menu";
import HeaderListItem from "./HeaderListItem";
import { withRouter } from "react-router-dom";
import Avatar from "../Avatar";

import "./style.scss";

const HeaderList = React.memo(props => {
  const { coin, imgData, fullName, isMobile, unverifiedEmployee } = props || "";
  let arrayData = [];
  arrayData.push(coin, unverifiedEmployee);
  const onChangeRouter = url => {
    props.history.push({
      pathname: url
    });
  };
  return (
    <>
      {isMobile ? (
        <div className="header-list-wrap">
          <Avatar imgData={imgData} />
          <div className="header-list-content">
            <span>{fullName}</span>
            <div className="header-list">
              {headerList.map((item, index) => {
                return (
                  <HeaderListItem
                    key={index}
                    name={item.name}
                    data={arrayData[index]}
                    clssName={item.clss}
                    onClick={() => onChangeRouter(item.url)}
                  />
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div className="header-list-desk">
          {headerList.map((item, index) => {
            return (
              <HeaderListItem
                key={index}
                name={item.name}
                data={arrayData[index]}
                clssName={item.clss}
                onClick={() => onChangeRouter(item.url)}
              />
            );
          })}
        </div>
      )}
    </>
  );
});
export default withRouter(HeaderList);
