import React from "react";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import "./style.scss";
const TextAreaComponent = React.memo(props => {
  const {
    handleChange,
    clssName,
    clssFeild,
    helperText,
    error,
    id,
    labelTxt,
    name,
    type,
    val,
    disabled,
    required,
    rowsVal,
    placeholderTxt,
    ref
  } = props;

  return (
    <FormControl
      className={`feild-group ${clssName ? clssName : "mr-b-25"}`}
      fullWidth>
      <TextField
        id={`${id ? id : "input"}`}
        disabled={disabled || false}
        className={`${clssFeild ? clssFeild : ""}`}
        variant="outlined"
        error={error || false}
        type={`${type ? type : "text"}`}
        label={`${labelTxt ? labelTxt : ""}`}
        value={val || ""}
        name={`${name ? name : id}`}
        onChange={handleChange}
        helperText={`${helperText ? helperText : ""}`}
        InputLabelProps={{ shrink: true }}
        required={required || false}
        multiline
        rows={rowsVal || 4}
        placeholder={placeholderTxt || ""}
        autoComplete="off"
        ref={ref}
      />
    </FormControl>
  );
});

export default TextAreaComponent;
