export const CONFIRM_GET_LIST_CONFIRM = "CONFIRM_GET_LIST_CONFIRM";
export const CONFIRM_GET_LIST_CONFIRM_SUCCESS =
  "CONFIRM_GET_LIST_CONFIRM_SUCCESS";
export const CONFIRM_SET_PAGE_CONFIRM = "CONFIRM_SET_PAGE_CONFIRM";
export const CONFIRM_SET_ROWS_PER_PAGE_CONFIRM =
  "CONFIRM_SET_ROWS_PER_PAGE_CONFIRM";
export const CONFIRM_SET_ORDER_CONFIRM = "CONFIRM_SET_ORDER_CONFIRM";
export const CONFIRM_SET_KEYWORD_CONFIRM = "CONFIRM_SET_KEYWORD_CONFIRM";
//UN
export const CONFIRM_GET_LIST_UNCONFIRM = "CONFIRM_GET_LIST_UNCONFIRM";
export const CONFIRM_GET_LIST_UNCONFIRM_SUCCESS =
  "CONFIRM_GET_LIST_UNCONFIRM_SUCCESS";
export const CONFIRM_SET_PAGE_UNCONFIRM = "CONFIRM_SET_PAGE_UNCONFIRM";
export const CONFIRM_SET_ROWS_PER_PAGE_UNCONFIRM =
  "CONFIRM_SET_ROWS_PER_PAGE_UNCONFIRM";
export const CONFIRM_SET_ORDER_UNCONFIRM = "CONFIRM_SET_ORDER_UNCONFIRM";
export const CONFIRM_SET_KEYWORD_UNCONFIRM = "CONFIRM_SET_KEYWORD_UNCONFIRM";

export const CONFIRM_SET_TAB_ACTIVE = "CONFIRM_SET_TAB_ACTIVE";

export const CONFIRM_SET_PAGE_UNCONFIRM_TO_CONFIRM =
  "CONFIRM_SET_PAGE_UNCONFIRM_TO_CONFIRM";
