import { EDIT_PROFILE_ME, CHANGE_PASSWORD } from "./constants";
import { takeLatest, put } from "redux-saga/effects";
import { reduxAction } from "../../store/action";
import requestAPI from "../../apis/requestApi";
import { go, push } from "connected-react-router";
import { toastMessage } from "../../utils/common";
import { TOAST_TYPE } from "../../utils/constants";
import {
  COMMON_SHOW_LOADING,
  COMMON_HANDLE_ERROR,
  COMMON_HIDE_LOADING
} from "../../store/constants";

function* editInfoProfileMe(action) {
  try {
    yield put(reduxAction(COMMON_SHOW_LOADING));
    const {
      full_name,
      gender,
      hr_role,
      documents,
      phone_backup,
      email,
      email_backup,
      working_address
    } = action.payload || {};
    let formData = new FormData();
    formData.append("full_name", full_name);
    formData.append("gender", gender);
    formData.append("hr_role", hr_role);
    formData.append("phone_backup", phone_backup);
    formData.append("email_backup", email_backup);
    formData.append("working_address", working_address);
    if (documents && documents.data) {
      formData.append("document", documents.data);
    }
    if (email) {
      formData.append("email", email);
    }
    // for (var pair of formData.entries()) {
    //   console.log(pair);
    // }
    const data = yield requestAPI.put(`hrs/me`, formData);
    if (data && data.data) {
      yield put(go("/information"));
    }
  } catch (err) {
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put(reduxAction(COMMON_HIDE_LOADING));
  }
}
function* onChangePasswordSaga(action) {
  try {
    yield put(reduxAction(COMMON_SHOW_LOADING));
    const data = yield requestAPI.put(`auth/password`, action.payload);
    if (data) {
      yield put(push("/profile/confirm-list"));
    }
  } catch (err) {
    if (err && err.data && err.data.errors) {
      yield toastMessage(TOAST_TYPE.ERROR, err.data.errors.full_messages[0]);
    }
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put(reduxAction(COMMON_HIDE_LOADING));
  }
}
export function* watchInformation() {
  yield takeLatest(EDIT_PROFILE_ME, editInfoProfileMe);
  yield takeLatest(CHANGE_PASSWORD, onChangePasswordSaga);
}
