import {
  GET_EMPLOYEE_DETAIL,
  GET_EMPLOYEE_DETAIL_SUCCESS,
  EMPLOYEE_SET_PAGE_UNCONFIRM_TO_CONFIRM,
  EDIT_PROFILE_USER_CONFIRM
} from "./constants";
import { COMMON_GET_USER_INFOR } from "../../store/constants";
import { takeLatest, put } from "redux-saga/effects";
import requestApi from "../../apis/requestApi";
import { reduxAction } from "../../store/action";
import {
  COMMON_SHOW_LOADING,
  COMMON_HANDLE_ERROR,
  COMMON_HIDE_LOADING,
  COMMON_SET_CLAIMED_COIN
} from "../../store/constants";
import { formatDate } from "../../utils/date";
import { DATE_FORMAT_DD_MM_YYYY } from "../../utils/constants";
import { push } from "connected-react-router";
function* getEmployeeSaga(action) {
  try {
    // yield put(reduxAction(COMMON_SHOW_LOADING));
    const res = yield requestApi.get("hrs/employees/" + action.payload);
    const { data } = res;
    if (data) {
      yield put(reduxAction(GET_EMPLOYEE_DETAIL_SUCCESS, data));
    }
  } catch (err) {
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put(reduxAction(COMMON_HIDE_LOADING));
  }
}

function* getUnconfirmtoConfirmSaga(action) {
  try {
    yield put(reduxAction(COMMON_SHOW_LOADING));
    const { userId, data } = action.payload;

    const res = yield requestApi.put(`/hrs/employees/${userId}/verify`, data);
    const { meta: { claimed_coin = 0 } = {} } = res.data || {};
    yield put(reduxAction(COMMON_SET_CLAIMED_COIN, claimed_coin));
    yield put(reduxAction(COMMON_GET_USER_INFOR));
    yield put(push("/profile/confirm-list"));
  } catch (err) {
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put(reduxAction(COMMON_HIDE_LOADING));
  }
}
function* editProfileUserConfirmSaga(action) {
  try {
    yield put(reduxAction(COMMON_SHOW_LOADING));
    const { userId, values } = action.payload;
    const {
      full_name,
      date_of_birth,
      nic_number,
      position,
      document = values.documents.data,
      monthly_income,
      contract_type,
      working_address,
      working_time,
      note
    } = values || {};
    let formData = new FormData();
    formData.append("full_name", full_name);
    if (date_of_birth) {
      formData.append(
        "date_of_birth",
        formatDate(date_of_birth, DATE_FORMAT_DD_MM_YYYY)
      );
    }
    formData.append("nic_number", nic_number);
    formData.append("document", document);
    if (position && position.value) {
      formData.append("position_id", position.value);
    }
    formData.append("monthly_income", monthly_income);
    formData.append("note", note);
    if (contract_type && contract_type.value) {
      formData.append("contract_type", contract_type.value);
    }
    formData.append("working_address", working_address);
    if (working_time && working_time.value) {
      formData.append("working_time", working_time.value);
    }
    // for (var pair of formData.entries()) {
    //   console.log(pair);
    // }
    const result = yield requestApi.put(`hrs/employees/${userId}`, formData);
    if (result) {
      yield put(push("/profile/confirm-list"));
    }
  } catch (err) {
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put(reduxAction(COMMON_HIDE_LOADING));
  }
}
export function* watchEmployee() {
  yield takeLatest(GET_EMPLOYEE_DETAIL, getEmployeeSaga);
  yield takeLatest(
    EMPLOYEE_SET_PAGE_UNCONFIRM_TO_CONFIRM,
    getUnconfirmtoConfirmSaga
  );
  yield takeLatest(EDIT_PROFILE_USER_CONFIRM, editProfileUserConfirmSaga);
}
