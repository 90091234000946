import { createSelector } from "reselect";

const getLoginInfoSelector = createSelector(
  state => state.commonsReducer.userInfor,
  userInfor => userInfor
);
const makeSelectServerError = createSelector(
  state => state.commonsReducer.serverError,
  serverError => serverError
);

const makeSelectPersonalInfo = createSelector(
  state => state.commonsReducer.userInfor,
  userInfor => userInfor
);
const makeSelectContractType = createSelector(
  state => state.commonsReducer.listContractType,
  listContractType => listContractType
);
const makeSelectIsClaimedCoin = createSelector(
  state => state.commonsReducer.claimedCoin,
  claimedCoin => claimedCoin
);

export {
  makeSelectServerError,
  makeSelectPersonalInfo,
  makeSelectIsClaimedCoin,
  getLoginInfoSelector,
  makeSelectContractType
};
