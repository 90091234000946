import React from "react";
import "./style.scss";
const LabelHeader = React.memo(props => {
  const { fullName } = props;
  return (
    <>
      <span className="label-chao">Chào,</span>
      <span className="label-login"> {fullName}</span>
    </>
  );
});

export default LabelHeader;
