import React from "react";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import "./style.scss";
const SelectComponent = React.memo(props => {
  const {
    handleChange,
    clssName,
    clssFeild,
    helperText,
    error,
    id,
    labelTxt,
    name,
    type,
    val,
    ranges,
    required
  } = props;
  return (
    <FormControl
      className={`feild-group ${clssName ? clssName : "mr-b-25"}`}
      fullWidth>
      <TextField
        select
        required={required || false}
        id={`${id ? id : "input"}`}
        className={`${clssFeild ? clssFeild : ""}`}
        variant="outlined"
        error={error || false}
        type={`${type ? type : "text"}`}
        label={`${labelTxt ? labelTxt : ""}`}
        value={val}
        name={`${name ? name : id}`}
        onChange={handleChange}
        helperText={`${helperText ? helperText : ""}`}
        InputLabelProps={{ shrink: true }}>
        {ranges.map(option => (
          <MenuItem
            key={option.value}
            value={option.value}
            className="select-menu-item">
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </FormControl>
  );
});

export default SelectComponent;
