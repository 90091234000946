import { takeLatest, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import { removeLocalStore } from "../../utils/localStore";
import get from "lodash.get";
import {
  COMMON_HANDLE_ERROR,
  COMMON_SET_SERVER_ERROR
} from "../../store/constants";
import { reduxAction } from "../action";

function* handleErrorSaga(action) {
  const { data, status } = action.payload;
  console.log("===>", data);
  if (status === 401) {
    removeLocalStore();
    yield put(push("/login"));
  }
  if (data) {
    yield put(reduxAction(COMMON_SET_SERVER_ERROR, get(data, "errors")));
  }
}

export function* watchError() {
  yield takeLatest(COMMON_HANDLE_ERROR, handleErrorSaga);
}
