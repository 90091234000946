import React from "react";

import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";

const InputComponentEnd = React.memo(props => {
  const {
    handleChange,
    clssName,
    clssFeild,
    helperText,
    error,
    id,
    labelTxt,
    name,
    type,
    val,
    disabled,
    required,
    valEnd,
    placeholderTxt,
    autoComplete
  } = props;
  return (
    <FormControl
      className={`feild-group ${clssName ? clssName : "mr-b-25"}`}
      fullWidth>
      <TextField
        autoComplete={autoComplete ? autoComplete : ""}
        id={`${id ? id : "input"}`}
        disabled={disabled || false}
        className={`${clssFeild ? clssFeild : ""}`}
        variant="outlined"
        error={error || false}
        type={`${type ? type : "text"}`}
        label={`${labelTxt ? labelTxt : ""}`}
        value={val}
        name={`${name ? name : id}`}
        onChange={handleChange}
        helperText={`${helperText ? helperText : ""}`}
        InputLabelProps={{ shrink: true }}
        required={required || false}
        placeholder={placeholderTxt}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {valEnd || props.children}
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  );
});

export default InputComponentEnd;
