import isEqual from "lodash/isEqual";
class Vali {
  constructor(data) {
    this.data = data;
    this.messenge = "";
  }

  min(min, error_messenge) {
    if (!this.messenge) {
      if (this.data.length < min) {
        this.messenge = error_messenge;
      }
    }
    return this;
  }
  max(max, error_messenge) {
    if (!this.messenge) {
      if (this.data.length > max) {
        this.messenge = error_messenge;
      }
    }
    return this;
  }
  lengths(min, max, error_messenge) {
    if (!this.messenge && this.data) {
      const val = this.data.toString();
      if (val.length < min || val.length > max) {
        this.messenge = error_messenge;
      }
    }
    return this;
  }
  numbers(error_messenge) {
    const regex = /^\d*$/;
    if (!this.messenge && this.data) {
      const result = regex.test(this.data);
      if (!result) {
        this.messenge = error_messenge;
      }
    }
    return this;
  }
  strings(error_messenge) {
    const regex = /^[a-zA-Z\s]*$/;

    if (!this.messenge && this.data) {
      const result = regex.test(this.data.trim());
      if (!result) {
        this.messenge = error_messenge;
      }
    }
    return this;
  }
  match(regex, error_messenge) {
    if (!this.messenge && this.data) {
      const result = regex.test(this.data);
      if (!result) {
        this.messenge = error_messenge;
      }
    }
    return this;
  }
  isEmail(error_messenge) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!this.messenge && this.data) {
      const result = regex.test(this.data);
      if (!result) {
        this.messenge = error_messenge;
      }
    }
    return this;
  }
  isSamePass(pattern, error_messenge) {
    if (!this.messenge && this.data) {
      if (!pattern && this.data) {
        this.messenge = "";
      }
      if (pattern && this.data) {
        const result = isEqual(pattern, this.data);
        if (!result) {
          this.messenge = error_messenge;
        } else {
          this.messenge = "";
        }
      }
    }

    return this;
  }
  isRequire(error_messenge) {
    if (typeof this.data === "object") {
      if (!Object.keys(this.data).length) {
        this.messenge = error_messenge;
      }
    } else {
      if (!this.data) {
        this.messenge = error_messenge;
      }
    }
    return this;
  }
  isDuplicate(originData, error_messenge) {
    const { data, url } = this.data || {};
    if (this.data && originData) {
      if (isEqual(data && data.name, originData.original_filename)) {
        this.messenge = error_messenge;
      }
    }

    return this;
  }
}

export default Vali;
