import React from "react";

import "./style.scss";
const RowDetail = props => {
  const { title, content, children, clssName } = props;

  return (
    <div className="row-detail">
      {title ? <div className="row-detail__title">{title}</div> : ""}
      {content ? <div className="row-detail__content">{content}</div> : ""}
      {React.Children.map(children, child => {
        return (
          <div
            className={
              clssName ? "row-detail__option " + clssName : "row-detail__option"
            }>
            {child}
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(RowDetail);
