import React from "react";
import logoImg from "../../../assets/images/logo.png";
import "./style.scss";
import { Link } from "react-router-dom";
const Logo = React.memo(props => {
  return (
    <div className="logo-main">
      <Link to="/">
        <img className="img-logo" src={logoImg} alt="interloan" />
      </Link>
    </div>
  );
});
export default Logo;
