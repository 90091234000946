export const headerList = [
  {
    id: 0,
    name: "Số xu:",
    clss: "",
    url: ""
  },
  {
    id: 1,
    name: "Chưa xác minh:",
    clss: "header-list__underline",
    url: "/profile/confirm-list"
  }
];
