import "./style.scss";
import gif from "../../assets/images/test-gif.gif";
import React from "react";
import { makeSelectPersonalInfo } from "../../store/selectors";
import { useSelector } from "react-redux";
import coinAudio from "../../assets/audio/coin.mp3";
const ModalCoinContent = ({ coin, isLogin }) => {
  const userInfo = useSelector(makeSelectPersonalInfo);
  const { full_name, companies = [] } = userInfo.personal_info || {};
  return (
    <div className={`coin-content ${isLogin ? "isLogin" : ""}`}>
      <img src={gif} alt="money" />
      {isLogin && (
        <div className="coin-content__welcome">
          Xin chào bạn{" "}
          <span className="coin-content__welcome--green">{full_name} - </span>
          <div className="coin-content__welcome--bold">
            {"HR "}
            {companies && companies[0] ? companies[0].name : "_"}
          </div>
        </div>
      )}
      <div className="coin-content__number">
        <div>
          Chúc mừng bạn đã nhận được{" "}
          <span className="coin-content__number--yellow">{coin}</span>
          <span className="coin-content__number--black">Xu</span>
        </div>
      </div>
      <audio autoPlay id="playAudio">
        <source src={coinAudio} type="audio/mpeg" />
      </audio>
    </div>
  );
};

export default React.memo(ModalCoinContent);
