import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormContent from "../../../components/FormContent";
import FormContainer from "../../../components/FormContainer";
import RowDetail from "../../../components/RowDetail";
import InputComponent from "../../../components/InputComponent";
import BackComponent from "../../../components/BackComponent";
import ButtonComponent from "../../../components/ButtonComponent";
import DatePickerCustom from "../../../components/DatePickerCustom";
import SelectComponent from "../../../components/SelectComponent";
import InputComponentEnd from "../../../components/InputComponentEnd";
import UploadComponent from "../../../components/UploadComponent";
import history from "../../../configs/history";
import { makeSelectEmployee } from "../selectors";
import { makeSelectContractType } from "../../../store/selectors";
import { workingTimeList, positionType } from "../../../constains";
import { reduxAction } from "../../../store/action";
import { COMMON_GET_LIST_CONTRACT_TYPE } from "../../../store/constants";
import { EDIT_PROFILE_USER_CONFIRM } from "../constants";
import useForm from "../../../hooks/useForm";
import Vali from "../../../utils/validator/validate";
import { withRouter } from "react-router-dom";
import { numberWithCommas } from "../../../utils/global";
const ConEmployeeDetailUpdate = props => {
  const { handleCancelEditProfile } = props;
  const dispatch = useDispatch();
  let schema = {};
  let data = useSelector(makeSelectEmployee);
  data.monthly_income = parseInt(data.monthly_income);
  const userId = props.match.params.detailId;
  const onEditProfileConfirm = () => {
    const bundle = {
      userId,
      values
    };
    dispatch(reduxAction(EDIT_PROFILE_USER_CONFIRM, bundle));
  };
  const {
    errors,
    values,
    handleChange,
    handleSelect,
    handleSubmit,
    handleUpload
  } = useForm(data, onEditProfileConfirm, schema, { isSameOrigin: true });
  const handleBack = useCallback(() => {
    history.goBack();
  });
  const {
    full_name,
    date_of_birth,
    nic_number,
    documents,
    position,
    monthly_income,
    note,
    contract_type,
    working_address,
    working_time
  } = values;
  schema.full_name = new Vali(values.full_name).isRequire(
    "Họ tên bắt buộc nhập"
  );
  schema.nic_number = new Vali(values.nic_number)
    .isRequire("Số chứng minh thư không được bỏ trống")
    .numbers("Số chứng minh thư phải là số")
    .lengths(9, 12, "CMND phải là số có 9 hay 12 ký tự");
  schema.monthly_income = new Vali(values.monthly_income)
    .isRequire("Mức thu nhập không được để trống")
    .numbers("Mức thu nhập phải là số");
  schema.working_time = new Vali(values.working_time).isRequire(
    "Vui lòng chọn thời gian công tác"
  );
  schema.contract_type = new Vali(values.contract_type).isRequire(
    "Vui lòng chọn loại hợp đồng"
  );
  schema.position = new Vali(values.position).isRequire(
    "Vui lòng chọn chức vụ"
  );
  schema.working_address = new Vali(values.working_address).isRequire(
    "Địa chỉ công tác không được bỏ trống"
  );
  useEffect(() => {
    dispatch(reduxAction(COMMON_GET_LIST_CONTRACT_TYPE));
  }, []);
  const listContractType = useSelector(makeSelectContractType);
  return (
    <div className="form-detail">
      <BackComponent handleBack={handleBack} contents="Danh sách nhân viên" />
      <FormContainer>
        <FormContent>
          {/* <RowDetail title="Nội dung" isTitle content="Chi tiết" name="name" /> */}
          <RowDetail title="Họ và tên" isTitle={true} clssName="custom-input">
            <InputComponent
              val={full_name ? full_name : ""}
              name="full_name"
              clssName="input-normal"
              handleChange={handleChange}
              error={errors && errors.full_name ? true : false}
              helperText={errors && errors.full_name ? errors.full_name : ""}
            />
          </RowDetail>
          <RowDetail title="Ngày sinh" clssName="custom-input">
            <DatePickerCustom
              className="datePicker-container"
              name="date_of_birth"
              value={date_of_birth ? date_of_birth : ""}
              handleChange={handleChange}
              dateFormat="dd/MM/yyyy"
            />
          </RowDetail>
          <RowDetail title="Số CMND" clssName="custom-input">
            <InputComponent
              clssName="input-normal"
              handleChange={handleChange}
              name="nic_number"
              val={nic_number ? nic_number : ""}
              error={!!errors.nic_number}
              helperText={errors.nic_number ? errors.nic_number : ""}
            />
          </RowDetail>
          <RowDetail title="Ảnh chân dung" clssName="custom-input">
            <UploadComponent
              type="onlyShow"
              className="--edit-personal upload--img only-show upload--horizontal"
              name="documents"
              txtBtnUpdate="Cập nhật"
              handleUpload={handleUpload}
              notShowPicture="false"
              files={documents && documents.url ? documents.url : ""}
              error={!!errors.documents}
              helperText={errors.documents ? errors.documents : ""}
            />
          </RowDetail>
        </FormContent>
        <FormContent>
          {/* <RowDetail title="Nội dung" isTitle content="Chi tiết" name="name" /> */}
          <RowDetail title="Chức vụ" clssName="custom-input">
            <SelectComponent
              val={String(position.value) || ""}
              clssName="input-normal"
              ranges={positionType}
              handleChange={handleSelect}
              name="position"
              error={!!errors.position}
              helperText={errors.position ? errors.position : ""}
            />
          </RowDetail>
          <RowDetail title="Mức thu nhập" clssName="custom-input">
            <InputComponentEnd
              id="monthly_income"
              name="monthly_income"
              clssName="input-normal"
              val={monthly_income ? numberWithCommas(monthly_income) : ""}
              textAligns="right"
              autoComplete="off"
              valEnd={"/Tháng"}
              handleChange={handleChange}
              error={!!errors.monthly_income}
              helperText={errors.monthly_income ? errors.monthly_income : ""}
            />
          </RowDetail>
          <RowDetail title="Thơi gian công tác" clssName="custom-input">
            <SelectComponent
              ranges={workingTimeList}
              name="working_time"
              clssName="input-normal"
              val={String(working_time.value) || ""}
              handleChange={handleSelect}
              error={!!errors.working_time}
              helperText={errors.working_time ? errors.working_time : ""}
            />
          </RowDetail>
          <RowDetail title="Địa chỉ công tác" clssName="custom-input">
            <InputComponent
              name="working_address"
              val={working_address ? working_address : ""}
              clssName="input-normal"
              handleChange={handleChange}
              error={!!errors.working_address}
              helperText={errors.working_address ? errors.working_address : ""}
            />
          </RowDetail>
          <RowDetail title="Loại hợp đồng LĐ" clssName="custom-input">
            <SelectComponent
              ranges={listContractType ? listContractType : ""}
              clssName="input-normal"
              name="contract_type"
              val={String(contract_type.value) || ""}
              handleChange={handleSelect}
              error={!!errors.contract_type}
              helperText={errors.contract_type ? errors.contract_type : ""}
            />
          </RowDetail>
          <RowDetail title="Ghi chú" clssName="custom-input">
            <InputComponent
              val={note ? note : ""}
              clssName="input-normal"
              placeholderTxt="Vd: Sắp nghỉ việc,..."
              handleChange={handleChange}
              name="note"
            />
          </RowDetail>
        </FormContent>
      </FormContainer>
      <p className="feild-error">
        {errors && errors.isSameOrigin ? errors.isSameOrigin : ""}
      </p>
      <div className="form-detail__btn">
        <ButtonComponent
          className="btn btn--white--c1 m-l-5"
          content="Hủy"
          onClick={handleCancelEditProfile}
        />
        <ButtonComponent
          className="btn btn--yellow--c1 m-l-5"
          content="Lưu"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default withRouter(ConEmployeeDetailUpdate);
