import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import "./style.scss";

const DialogTitle = withStyles(() => ({
  root: {
    position: "relative",
    margin: 0,
    padding: "24px 30px"
  },
  closeButton: {
    position: "absolute",
    right: "10px",
    top: "10px",
    color: "#00000033"
  },
  title: {
    fontFamily: "RobotoSlab-Bold",
    fontSize: "16px",
    color: "#5DAD72"
  }
}))(props => {
  const { children, classes, onClose } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <h4 className={classes.title}>{children}</h4>
      {onClose ? (
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles(() => ({
  root: {
    margin: 0,
    padding: "0 30px 24px",
    textAlign: "center"
  }
}))(MuiDialogContent);

const ModalCustom = React.memo(props => {
  const {
    openModel,
    handleClose,
    info,
    maxWidthDialog,
    fullWidthDialog,
    classWrapp,
    classContent
  } = props;
  const { title = "", id = "" } = info;

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby={id}
      open={openModel}
      className={`modal-wrapp ${classWrapp}`}
      fullWidth={fullWidthDialog ? fullWidthDialog : true}
      maxWidth={maxWidthDialog || "sm"}
      id={id}>
      <DialogTitle id={id} onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent
        className={`modal-dialog-content modal-content ${classContent}`}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
});

export default ModalCustom;
