import { put, takeLatest } from "redux-saga/effects";

import {
  COMMON_SHOW_LOADING,
  COMMON_HANDLE_ERROR,
  COMMON_HIDE_LOADING,
  COMMON_GET_USER_INFOR,
  COMMON_GET_USER_INFOR_SUCCESS,
  COMMON_GET_LIST_CONTRACT_TYPE,
  COMMON_GET_LIST_CONTRACT_TYPE_SUCCESS,
  COMMON_GET_NOTIFICATIONS,
  COMMON_GET_NOTIFICATIONS_SUCCESS
} from "../constants";
import requestApi from "../../apis/requestApi";
import { reduxAction } from "../action";
function* getUserInforSaga(action) {
  try {
    yield put({
      type: COMMON_SHOW_LOADING
    });
    let data = yield requestApi.get("users/infos");
    yield put(reduxAction(COMMON_GET_USER_INFOR_SUCCESS, data.data));
  } catch (err) {
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put({
      type: COMMON_HIDE_LOADING
    });
  }
}
function* getListContractTypeSaga(action) {
  try {
    yield put({
      type: COMMON_SHOW_LOADING
    });
    let data = yield requestApi.get("staffs/contract_types");
    if (data && data.data && data.data.contract_types) {
      yield put(
        reduxAction(
          COMMON_GET_LIST_CONTRACT_TYPE_SUCCESS,
          data.data.contract_types
        )
      );
    }
  } catch (err) {
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put({
      type: COMMON_HIDE_LOADING
    });
  }
}

function* getNotificationSaga() {
  try {
    yield put({
      type: COMMON_SHOW_LOADING
    });
    let data = yield requestApi.put("notifications/mark_as_read_all");
    if (data && data.data && data.data.contract_types) {
      yield put(reduxAction(COMMON_GET_NOTIFICATIONS_SUCCESS, data.data));
    }
  } catch (err) {
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put({
      type: COMMON_HIDE_LOADING
    });
  }
}
export function* watchCommons() {
  yield takeLatest(COMMON_GET_USER_INFOR, getUserInforSaga);
  yield takeLatest(COMMON_GET_LIST_CONTRACT_TYPE, getListContractTypeSaga);
  yield takeLatest(COMMON_GET_NOTIFICATIONS, getNotificationSaga);
}
