import isEqual from "lodash/isEqual";
export const isDulicateFile = (value, origindata) => {
  let error;
  const { original_filename } = origindata || {};
  const { name } = value || {};
  if (isEqual(original_filename, name)) {
    error = "File upload lên bị trùng";
  }
  if (original_filename && !original_filename.length) {
    error = "File không được rỗng";
  }
  if (name && !name.length) {
    error = "File không được rỗng";
  }
  return error;
};
