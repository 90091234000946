export const IS_REMEMBER = "IS_REMEMBER";
export const PASSWORD_REMEMBER = "pw_rb";
export const DATE_FORMMAT = "HH:mm DD/MM/YYYY";
export const DATE_FORMAT_DD_MM_YYYY = "DD/MM/YYYY";
export const UNCONFIRM = "unconfirm";
export const CONFIRM = "confirm";
export const CLOSE_TIME_MODAL = 2000;
export const TOAST_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  WARN: "warn",
  INFO: "info",
  CUSTOM: "custom"
};
