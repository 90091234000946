import React, { useState, useCallback, useEffect } from "react";
import "./style.scss";
import { useDevice } from "../../hooks/useDevice";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import LabelHeader from "../LabelHeader";
import ButtonComponent from "../ButtonComponent";
import HeaderList from "../HeaderList";
import Avatar from "../Avatar";
import PersonIcon from "@material-ui/icons/Person";
import { useDispatch } from "react-redux";
import { reduxAction } from "../../store/action";
import LockIcon from "@material-ui/icons/Lock";
import { LOGOUT } from "../../containers/Login/constants";
import { OPEN_FORM_CHANGE_PASSWORD } from "../../containers/Information/constants";
import { withRouter } from "react-router-dom";
const DropDownMenu = React.memo(props => {
  const toggleMenu = React.createRef();
  const dispatch = useDispatch();
  const { fullName, imgData, coin, unverifiedEmployee, companyLogo } = props;
  const [isOpen, setOpen] = useState(null);
  const screenSize = useDevice();
  const { isMobile, isTablet } = screenSize;
  const { original_filename, url } = companyLogo || "";
  useEffect(() => {
    return () => {
      dispatch(reduxAction(OPEN_FORM_CHANGE_PASSWORD, false));
    };
  }, []);
  const openDropDown = event => {
    setOpen(prevIsOpen => !prevIsOpen);
  };
  const onClickAway = React.useCallback(callback => {
    setOpen(null);
  });
  const onLogOut = useCallback(() => {
    dispatch(reduxAction(LOGOUT));
  });
  const onChangePassword = useCallback(param => {
    props.history.push("/profile/information");
    dispatch(reduxAction(OPEN_FORM_CHANGE_PASSWORD, param));
  });
  return (
    <>
      <ClickAwayListener onClickAway={onClickAway}>
        <div className="drop-down" onClick={openDropDown}>
          {!isMobile && !isTablet ? (
            <>
              <div className="hr-logo">
                <img src={url} alt={original_filename} />
              </div>

              <Avatar imgData={imgData} />
              <a>
                <LabelHeader fullName={fullName} />
              </a>
            </>
          ) : (
            ""
          )}
          <div className="drop-down-container" ref={toggleMenu}>
            {!isMobile && !isTablet ? (
              <ArrowDropDownIcon fontSize="small" />
            ) : (
              <Avatar imgData={imgData} />
            )}
            {isOpen ? (
              <div className="drop-down__content">
                {isMobile || isTablet ? (
                  <HeaderList
                    coin={coin}
                    imgData={imgData}
                    fullName={fullName}
                    isMobile={true}
                    unverifiedEmployee={unverifiedEmployee}
                  />
                ) : (
                  ""
                )}
                <ul className="drop-down--list">
                  <li onClick={() => onChangePassword(false)}>
                    <PersonIcon color="primary" fontSize="small" /> Thông tin cá
                    nhân
                  </li>
                  <li onClick={() => onChangePassword(true)}>
                    <LockIcon color="primary" fontSize="small" />
                    Đổi mật khẩu
                  </li>
                  <li>
                    <ButtonComponent
                      content="Đăng xuất"
                      className="button-logout"
                      onClick={onLogOut}
                    />
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </ClickAwayListener>
    </>
  );
});

export default withRouter(DropDownMenu);
