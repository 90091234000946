/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router/immutable";

import history from "../../configs/history";
/** import main reducer load sync */
import commonsReducer from "./commons";
import layoutReducer from "./layout";
import confirmReducer from "../../containers/Confirm/reducer";
import loginReducer from "../../containers/Login/reducer";
import informationReducer from "../../containers/Information/reducer";
import employeeDetailReducer from "../../containers/EmployeeDetail/reducer";
import forgotPassReducer from "../../containers/ForgotPassword/reducer";
const createReducer = (injectedReducers = {}) => (state, action) =>
  combineReducers({
    router: connectRouter(history),
    commonsReducer,
    layoutReducer,
    confirmReducer,
    loginReducer,
    informationReducer,
    forgotPassReducer,
    employeeDetailReducer,
    ...injectedReducers
  })(action.type === "LOGOUT_SUCCESS" ? undefined : state, action);
export default createReducer;
