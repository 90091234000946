import React from "react";
import { Switch } from "react-router-dom";
import PublicRoute from "../components/Router/PublicRoute";
import PrivateRoute from "../components/Router/PrivateRoute";
import Login from "../containers/Login/loadable";

import ProfileRouter from "./profile";
import ForgotPassword from "../containers/ForgotPassword/loadable";

const Router = () => {
  return (
    <Switch>
      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/forgotpassword" component={ForgotPassword} />
      <PublicRoute exact path="/" component={Login} />
      <PrivateRoute path="/profile" component={ProfileRouter} />
    </Switch>
  );
};

export default Router;
