import { all } from "redux-saga/effects";
import { watchError } from "./errors";
import { watchLogin } from "../../containers/Login/saga";
import { watchConfirm } from "../../containers/Confirm/saga";
import { watchInformation } from "../../containers/Information/saga";
import { watchCommons } from "./commons";
import { watchEmployee } from "../../containers/EmployeeDetail/saga";
import { watchForgotPass } from "../../containers/ForgotPassword/saga";
export default function* rootSaga() {
  yield all([
    watchConfirm(),
    watchLogin(),
    watchForgotPass(),
    watchInformation(),
    watchError(),
    watchCommons(),
    watchEmployee()
  ]);
}
