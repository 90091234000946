import React, { useState, useEffect } from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import InformationDetail from "./InformationDetail";
import { makeSelectPersonalInfo } from "../../store/selectors";
import { makeSelectIsChangePassword } from "./selectors";
import InformationDetailUpdate from "./InformationDetailUpdate";
import RowDetail from "../../components/RowDetail";
import ButtonComponent from "../../components/ButtonComponent";
import ChangePassword from "../../components/ChangePassword";
import ModalCoin from "../../components/ModalCoin";
const Information = props => {
  let userInfo = useSelector(makeSelectPersonalInfo);
  const isChangePassword = useSelector(makeSelectIsChangePassword);
  let [isEditProfile, setIsEditProfile] = useState(false);
  let { personal_info } = userInfo || {};

  const handleEditProfile = param => {
    setIsEditProfile(param);
  };

  useEffect(() => {
    if (isChangePassword) {
      handleEditProfile(false);
    }
  }, [isChangePassword]);

  return (
    <div className="information">
      <ModalCoin />
      {isChangePassword ? (
        <ChangePassword />
      ) : !isEditProfile ? (
        <>
          <InformationDetail data={personal_info} />
          <RowDetail isViewDetail={false}>
            <ButtonComponent
              className="btn btn--yellow--c1 m-l-5"
              content="Chỉnh sửa"
              onClick={() => handleEditProfile(true)}
            />
          </RowDetail>
        </>
      ) : (
        <InformationDetailUpdate
          data={personal_info}
          handleEditProfile={handleEditProfile}
        />
      )}
    </div>
  );
};
export default React.memo(Information);
