import React from "react";
import "./style.scss";
import left from "../../assets/images/arrow-left.png";
const BackComponent = React.memo(props => {
  const { handleBack, contents } = props;
  return (
    <div className="back">
      <a onClick={handleBack}>
        <span className="back__img">
          <img src={left} alt="come back" />
        </span>
        <span className="back__text">{contents ? contents : ""}</span>
      </a>
    </div>
  );
});

export default BackComponent;
