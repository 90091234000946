import "./style.scss";
import React from "react";

const ButtonComponent = React.memo(props => {
  const { className, content } = props;
  return (
    <button {...props} className={`btn ${className}`}>
      {content}
    </button>
  );
});
export default ButtonComponent;
