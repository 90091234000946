import produce from "immer";
import {
  COMMON_GET_USER_INFOR_SUCCESS,
  COMMON_SET_SERVER_ERROR,
  COMMON_GET_LIST_CONTRACT_TYPE_SUCCESS,
  COMMON_SET_CLAIMED_COIN,
  COMMON_GET_NOTIFICATIONS_SUCCESS
} from "../constants";

export const initialState = {
  userInfor: {},
  serverError: {},
  listContractType: [],
  claimedCoin: { value: undefined, loginStatus: false },
  notification: {},
  isLogin: false
};
const commonsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case COMMON_GET_USER_INFOR_SUCCESS:
        draft.userInfor = action.payload;
        const {
          isLogin,
          personal_info: { claimed_coin } = {}
        } = action.payload;
        if (isLogin) {
          draft.claimedCoin.loginStatus = true;
          draft.claimedCoin.value = claimed_coin;
        }
        break;
      case COMMON_SET_SERVER_ERROR:
        draft.serverError = action.payload;
        break;
      case COMMON_GET_LIST_CONTRACT_TYPE_SUCCESS:
        draft.listContractType = action.payload;
        break;
      case COMMON_SET_CLAIMED_COIN:
        draft.claimedCoin.value = action.payload;
        draft.claimedCoin.loginStatus = false;
        break;
      case COMMON_GET_NOTIFICATIONS_SUCCESS:
        draft.notification = action.payload;
        break;
      default:
        return state;
    }
  });
export default commonsReducer;
