import React, { memo, useState } from "react";

import "./style.scss";

import { UNCONFIRM } from "../../utils/constants";
import UnEmployeeDetail from "./UnEmployeeDetail";
import ConEmployeeDetail from "./ConEmployeeDetail";
import ConEmployeeDetailUpdate from "./ConEmployeeDetailUpdate";
const EmployeeDetail = memo(props => {
  const [isEditConfirmUser, setEditConfirmUser] = useState(false);
  const handleEditProfileConfirm = () => {
    setEditConfirmUser(previous => {
      return !previous;
    });
  };
  const { userType } = props.match.params;
  const renderContent = type => {
    if (type === UNCONFIRM) {
      return <UnEmployeeDetail />;
    } else {
      if (!isEditConfirmUser) {
        return (
          <ConEmployeeDetail handleEditProfile={handleEditProfileConfirm} />
        );
      } else {
        return (
          <ConEmployeeDetailUpdate
            handleCancelEditProfile={handleEditProfileConfirm}
          />
        );
      }
    }
  };

  return <div>{renderContent(userType)}</div>;
});
export default EmployeeDetail;
