import React, { useEffect, useCallback, useState } from "react";
import { reduxAction } from "../../store/action";
import { CLOSE_TIME_MODAL } from "../../utils/constants";
import { COMMON_SET_CLAIMED_COIN } from "../../store/constants";
import { useSelector, useDispatch } from "react-redux";
import { makeSelectIsClaimedCoin } from "../../store/selectors";
import ModalCustom from "../ModalCustom";
import ModalCoinContent from "../ModalCoinContent";
const ModalCoin = ({ coin, isLogin }) => {
  const [isOpen, setOpen] = useState(true);
  const dispatch = useDispatch();
  const claimedCoin = useSelector(makeSelectIsClaimedCoin);

  useEffect(() => {
    if (claimedCoin && claimedCoin.value) {
      setTimeout(() => {
        setOpen(false);
        dispatch(reduxAction(COMMON_SET_CLAIMED_COIN, 0));
      }, CLOSE_TIME_MODAL);
    }
  }, [claimedCoin]);

  const handleCloseModal = useCallback(() => {
    setOpen(false);
    dispatch(reduxAction(COMMON_SET_CLAIMED_COIN, 0));
  }, []);

  return (
    <div className="coin-modal">
      {!!claimedCoin.value && (
        <ModalCustom
          openModel={isOpen}
          info={{ id: "coin-modal", title: "" }}
          handleClose={handleCloseModal}>
          <ModalCoinContent
            isLogin={claimedCoin.loginStatus}
            coin={claimedCoin.value || 0}
          />
        </ModalCustom>
      )}
    </div>
  );
};

export default React.memo(ModalCoin);
