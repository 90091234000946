import React from "react";
import "./style.scss";

const StatusComponent = React.memo(props => {
  const { isActive, disableIcon, activeIcon, name, handleOnClick } = props;
  // const [toggleValue, toogleHandler] = useToogle(isActive);
  const handleClick = () => {
    handleOnClick(name, isActive);
  };
  return (
    <div className="row-detail__status">
      <img
        className="status-img"
        onClick={handleClick}
        alt="active_status"
        src={isActive ? activeIcon : disableIcon}
      />
    </div>
  );
});
export default StatusComponent;
