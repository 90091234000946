import { put, takeLatest } from "redux-saga/effects";
import requestApi from "../../apis/requestApi";
import { go, push } from "connected-react-router";
import { toastMessage } from "../../utils/common";
import { TOAST_TYPE } from "../../utils/constants";
import {
  COMMON_SHOW_LOADING,
  COMMON_HIDE_LOADING,
  COMMON_HANDLE_ERROR
} from "../../store/constants";
import {
  VERIFY_PHONE,
  VERIFY_PHONE_FINISH,
  VERIFY_OTP,
  VERIFY_OTP_FINISH,
  FORGOT_RESET_PASSWORD
} from "./constants";
import { reduxAction } from "../../store/action";
import { setLocalStore, removeLocalStore } from "../../utils/localStore";

function* verifyPhoneExistSaga(action) {
  try {
    yield put({
      type: COMMON_SHOW_LOADING
    });
    const data = yield requestApi.post(
      "/auth/password/phone_check",
      action.payload
    );
    if (data && data.data) {
      yield put(reduxAction(VERIFY_PHONE_FINISH, data.data));
    }
  } catch (err) {
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put({
      type: COMMON_HIDE_LOADING
    });
  }
}
function* verifyOTPSaga(action) {
  try {
    yield put({
      type: COMMON_SHOW_LOADING
    });
    const data = yield requestApi.post(
      "/auth/password/verify_otp",
      action.payload
    );
    if (data && data.data) {
      const { headers } = data;
      yield setLocalStore(headers);
      yield put(reduxAction(VERIFY_OTP_FINISH, data.data));
    }
  } catch (err) {
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put({
      type: COMMON_HIDE_LOADING
    });
  }
}
function* resetPassword(action) {
  try {
    yield put(reduxAction(COMMON_SHOW_LOADING));
    const data = yield requestApi.put(`auth/password`, action.payload);
    if (data) {
      yield removeLocalStore();
      yield put(push("/"));
    }
  } catch (err) {
    if (err && err.data && err.data.errors) {
      yield toastMessage(TOAST_TYPE.ERROR, err.data.errors.full_messages[0]);
    }
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put(reduxAction(COMMON_HIDE_LOADING));
  }
}
export function* watchForgotPass() {
  yield takeLatest(VERIFY_PHONE, verifyPhoneExistSaga);
  yield takeLatest(VERIFY_OTP, verifyOTPSaga);
  yield takeLatest(FORGOT_RESET_PASSWORD, resetPassword);
}
