import React, { useEffect } from "react";
import Loading from "./components/Loading";
import Routes from "./routers";
import { useDispatch } from "react-redux";
import "./App.scss";
import { getToken } from "./utils/localStore";
import { reduxAction } from "./store/action";
import { COMMON_GET_USER_INFOR } from "./store/constants";
const App = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (getToken()) {
      dispatch(reduxAction(COMMON_GET_USER_INFOR));
    }
  }, []);

  return (
    <div className="app">
      <Loading />
      <Routes />
    </div>
  );
};

export default App;
