import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../components/Router/PrivateRoute";
import Confirm from "../containers/Confirm/loadable";
import EmployeeDetail from "../containers/EmployeeDetail";
const ConfirmRouter = () => {
  return (
    <Switch>
      <PrivateRoute exact path="/profile/confirm-list" component={Confirm} />
      <PrivateRoute
        exact
        path="/profile/confirm-list/:userType(confirm|unconfirm)/detail/:detailId"
        component={EmployeeDetail}
      />
    </Switch>
  );
};

export default ConfirmRouter;
