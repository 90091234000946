import React, { memo, useCallback, useEffect, useState } from "react";
import BackComponent from "../../../components/BackComponent";
import "./style.scss";
import RowDetail from "../../../components/RowDetail";
import defaultImg from "../../../assets/images/default-user.png";
import InputNormal from "../../../components/InputNormal";
import ButtonComponent from "../../../components/ButtonComponent";
import history from "../../../configs/history";
import FormContent from "../../../components/FormContent";
import FormContainer from "../../../components/FormContainer";
import { useDispatch, useSelector } from "react-redux";
import { reduxAction } from "../../../store/action";
import { GET_EMPLOYEE_DETAIL } from "../constants";
import { makeSelectEmployee } from "../selectors";
import { withRouter } from "react-router-dom";
import { numberWithCommas } from "../../../utils/global";
import ModalCustom from "../../../components/ModalCustom";
import { formatDate } from "../../../utils/date";
import { DATE_FORMAT_DD_MM_YYYY } from "../../../utils/constants";
const ConEmployeeDetail = memo(props => {
  const { handleEditProfile } = props;
  const [isViewMoreImage, setIsViewMoreImage] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector(makeSelectEmployee);

  useEffect(() => {
    const { detailId } = props.match.params;
    dispatch(reduxAction(GET_EMPLOYEE_DETAIL, detailId));
  }, []);

  const handleBack = useCallback(() => {
    history.goBack();
  });
  const openModalImage = useCallback(value => {
    setIsViewMoreImage(value);
  });

  const {
    full_name,
    date_of_birth,
    nic_number,
    documents = {},
    position,
    monthly_income,
    note = "",
    contract_type = {},
    working_address,
    working_time = {}
  } = data;
  return (
    <div className="form-detail">
      <BackComponent handleBack={handleBack} contents="Danh sách đã xác minh" />
      <FormContainer>
        <FormContent>
          {/* <RowDetail title="Nội dung" isTitle content="Chi tiết" name="name" /> */}
          <RowDetail title="Họ & tên" content={full_name} />
          <RowDetail
            title="Ngày sinh"
            content={
              date_of_birth
                ? formatDate(date_of_birth, DATE_FORMAT_DD_MM_YYYY)
                : ""
            }
          />
          <RowDetail title="Số CMND" content={nic_number} />
          <RowDetail
            title="Ảnh chân dung"
            content={
              <img
                alt="portrait"
                src={documents ? documents.url : defaultImg}
                onClick={openModalImage}
              />
            }
          />
        </FormContent>
        <FormContent>
          {/* <RowDetail title="Nội dung" isTitle content="Chi tiết" name="name" /> */}
          {/* <RowDetail title="Công ty" content={company && company.label} /> */}
          <RowDetail title="Chức vụ" content={position && position.label} />
          <RowDetail
            title="Mức thu nhập"
            content={
              monthly_income ? numberWithCommas(monthly_income) + " VND" : ""
            }
          />
          <RowDetail title="Thơi gian công tác" content={working_time.label} />
          <RowDetail title="Địa chỉ công tác" content={working_address} />
          <RowDetail title="Loại hợp đồng LĐ" content={contract_type.label} />
          <RowDetail
            title="Ghi chú"
            content={
              <InputNormal
                disabled={true}
                value={note}
                // placeholder="Vd: Sắp nghỉ việc,..."
              />
            }
          />
        </FormContent>
      </FormContainer>

      <div className="form-detail__btn">
        <ButtonComponent
          className="btn btn--yellow--c1 m-l-5"
          content="Chỉnh sửa"
          onClick={handleEditProfile}
        />
      </div>
      {isViewMoreImage ? (
        <ModalCustom
          openModel={isViewMoreImage}
          info={{ id: "coin-modal", title: "" }}
          handleClose={() => openModalImage(false)}
          maxWidthDialog="md">
          <img
            alt="portrait"
            className="modal-image"
            src={documents ? documents.url : defaultImg}
          />
        </ModalCustom>
      ) : (
        ""
      )}
    </div>
  );
});
export default withRouter(ConEmployeeDetail);
