import React, { memo, useCallback, useState, useEffect, useRef } from "react";
import BackComponent from "../../../components/BackComponent";
import "./style.scss";
import RowDetail from "../../../components/RowDetail";
import defaultImg from "../../../assets/images/default-user.png";
import InputNormal from "../../../components/InputNormal";
import ButtonComponent from "../../../components/ButtonComponent";
import history from "../../../configs/history";
import FormContent from "../../../components/FormContent";
import FormContainer from "../../../components/FormContainer";
import StatusComponent from "../../../components/StatusComponent";
import { cancel, cancelRed } from "../../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { reduxAction } from "../../../store/action";
import {
  GET_EMPLOYEE_DETAIL,
  EMPLOYEE_SET_PAGE_UNCONFIRM_TO_CONFIRM
} from "../constants";
import { makeSelectEmployee } from "../selectors";
import { withRouter } from "react-router-dom";
import {
  CONFIRM,
  TOAST_TYPE,
  DATE_FORMAT_DD_MM_YYYY
} from "../../../utils/constants";
import { toastMessage } from "../../../utils/common";
import { numberWithCommas } from "../../../utils/global";
import ModalCustom from "../../../components/ModalCustom";
import { formatDate } from "../../../utils/date";
const UnEmployeeDetail = memo(props => {
  const dispatch = useDispatch();
  const data = useSelector(makeSelectEmployee);
  const [isViewMoreImage, setIsViewMoreImage] = useState(false);
  let isFirst = useRef(undefined);
  const [form, setForm] = useState({
    full_name_status: "",
    date_of_birth_status: "",
    nic_number_status: "",
    avatar_status: "",
    company_status: "",
    position_status: "",
    monthly_income_status: "",
    working_time_status: "",
    working_address_status: "",
    contract_type_status: "",
    note_status: ""
  });
  const { detailId } = props.match.params;

  useEffect(() => {
    dispatch(reduxAction(GET_EMPLOYEE_DETAIL, detailId));
  }, []);
  const openModalImage = useCallback(value => {
    setIsViewMoreImage(value);
  });

  const convertToServerData = form => {
    const {
      full_name_status,
      date_of_birth_status,
      nic_number_status,
      avatar_status,
      company_status,
      position_status,
      monthly_income_status,
      working_time_status,
      working_address_status,
      contract_type_status,
      note_status
    } = form;
    return {
      full_name: full_name_status,
      date_of_birth: date_of_birth_status,
      nic_number: nic_number_status,
      avatar: avatar_status,
      position: position_status,
      company: company_status,
      monthly_income: monthly_income_status,
      note: note_status,
      contract_type: contract_type_status,
      working_address: working_address_status,
      working_time: working_time_status
    };
  };

  const handleOnChange = useCallback(e => {
    const { name, value } = e.target;
    if (name === "note_status") {
      isFirst.current = true;
    }

    setForm(previous => {
      return {
        ...previous,
        [name]: value
      };
    });
  });

  const handleBack = useCallback(() => {
    history.goBack();
  });

  const handleClick = useCallback((name, value) => {
    setForm(previous => {
      return {
        ...previous,
        [name]: !previous[name]
      };
    });
  }, []);

  const handleSumbit = useCallback(type => {
    let data = {};
    if (type !== CONFIRM) {
      if (!isDirty()) {
        toastMessage(TOAST_TYPE.ERROR, "Hãy chọn thông tin sai");
        return;
      }
      let dataConvert = convertToServerData(form);
      Object.keys(convertToServerData(dataConvert)).map(key => {
        if (dataConvert[key]) {
          if (key === "note") {
            data[key] = dataConvert[key];
          } else {
            data[key] = false;
          }
        }
        return key;
      });
    } else {
      if (isDirty(CONFIRM)) {
        toastMessage(
          TOAST_TYPE.ERROR,
          "Tồn tại thông tin sai. Bạn hãy từ chối hồ sơ"
        );
        return;
      }
      data["note"] = form["note_status"];
    }

    dispatch(
      reduxAction(EMPLOYEE_SET_PAGE_UNCONFIRM_TO_CONFIRM, {
        userId: detailId,
        data
      })
    );
  });

  const isDirty = type => {
    //CONFIRM STATUS
    //REJECT
    let isDirty = false;

    for (let key in form) {
      if (type === CONFIRM) {
        if (form[key] && key !== "note_status") {
          isDirty = true;
        }
      } else {
        if (form[key]) {
          isDirty = true;
        }
      }
    }
    return isDirty;
  };

  const {
    full_name_status,
    date_of_birth_status,
    nic_number_status,
    avatar_status,
    // company_status,
    position_status,
    monthly_income_status,
    working_time_status,
    working_address_status,
    contract_type_status,
    note_status
  } = form;

  const {
    full_name,
    date_of_birth,
    nic_number,
    documents = {},
    position,
    monthly_income,
    note = "",
    contract_type = {},
    working_address,
    working_time = {}
  } = data;

  return (
    <div className="form-detail">
      <BackComponent
        handleBack={handleBack}
        contents="Danh sách chưa xác minh"
      />
      <FormContainer>
        <FormContent>
          {/* <RowDetail title="Nội dung" isTitle content="Chi tiết" name="name">
            <br />
          </RowDetail> */}
          <RowDetail title="Họ & tên" content={full_name}>
            <StatusComponent
              activeIcon={cancelRed}
              disableIcon={cancel}
              isActive={full_name_status}
              name="full_name_status"
              handleOnClick={handleClick}
            />
          </RowDetail>
          <RowDetail
            title="Ngày sinh"
            content={
              date_of_birth
                ? formatDate(date_of_birth, DATE_FORMAT_DD_MM_YYYY)
                : ""
            }>
            <StatusComponent
              activeIcon={cancelRed}
              disableIcon={cancel}
              isActive={date_of_birth_status}
              name="date_of_birth_status"
              handleOnClick={handleClick}
            />
          </RowDetail>
          <RowDetail title="Số CMND" content={nic_number}>
            <StatusComponent
              activeIcon={cancelRed}
              disableIcon={cancel}
              isActive={nic_number_status}
              name="nic_number_status"
              handleOnClick={handleClick}
            />
          </RowDetail>
          <RowDetail
            title="Ảnh chân dung"
            content={
              <img
                alt="portrait"
                src={documents ? documents.url : defaultImg}
                onClick={openModalImage}
              />
            }>
            <StatusComponent
              activeIcon={cancelRed}
              disableIcon={cancel}
              isActive={avatar_status}
              name="avatar_status"
              handleOnClick={handleClick}
            />
          </RowDetail>
        </FormContent>
        <FormContent>
          {/* <RowDetail title="Nội dung" isTitle content="Chi tiết" name="name">
            <br />
          </RowDetail> */}
          {/* <RowDetail title="Công ty" content="Mc Donald">
            <StatusComponent
              activeIcon={cancelRed}
              disableIcon={cancel}
              isActive={company_status}
              name="company_status"
              handleOnClick={handleClick}
            />
          </RowDetail> */}
          <RowDetail title="Chức vụ" content={position && position.label}>
            <StatusComponent
              activeIcon={cancelRed}
              disableIcon={cancel}
              isActive={position_status}
              name="position_status"
              handleOnClick={handleClick}
            />
          </RowDetail>
          <RowDetail
            title="Mức thu nhập"
            content={
              monthly_income ? numberWithCommas(monthly_income) + " VND" : ""
            }>
            <StatusComponent
              activeIcon={cancelRed}
              disableIcon={cancel}
              isActive={monthly_income_status}
              name="monthly_income_status"
              handleOnClick={handleClick}
            />
          </RowDetail>
          <RowDetail title="Thơi gian công tác" content={working_time.label}>
            <StatusComponent
              activeIcon={cancelRed}
              disableIcon={cancel}
              isActive={working_time_status}
              name="working_time_status"
              handleOnClick={handleClick}
            />
          </RowDetail>
          <RowDetail title="Địa chỉ công tác" content={working_address}>
            <StatusComponent
              activeIcon={cancelRed}
              disableIcon={cancel}
              isActive={working_address_status}
              name="working_address_status"
              handleOnClick={handleClick}
            />
          </RowDetail>
          <RowDetail title="Loại hợp đồng LĐ" content={contract_type.label}>
            <StatusComponent
              activeIcon={cancelRed}
              disableIcon={cancel}
              isActive={contract_type_status}
              name="contract_type_status"
              handleOnClick={handleClick}
            />
          </RowDetail>
          <RowDetail
            title="Ghi chú"
            clssName="test"
            content={
              <InputNormal
                name="note_status"
                placeholder="Vd: Sắp nghỉ việc,..."
                value={isFirst.current ? note_status : note || ""}
                onChange={handleOnChange}
              />
            }>
            <div />
          </RowDetail>
        </FormContent>
      </FormContainer>
      <div className="form-detail__btn">
        <ButtonComponent
          className="btn btn--white--c1"
          onClick={() => {
            handleSumbit();
          }}
          // disabled={!isDirty()}
          content="Từ chối"
        />
        <ButtonComponent
          className="btn btn--yellow--c1 m-l-5"
          // disabled={isDirty(CONFIRM)}
          onClick={() => {
            handleSumbit(CONFIRM);
          }}
          content="Xác minh"
        />
      </div>
      {isViewMoreImage ? (
        <ModalCustom
          openModel={isViewMoreImage}
          info={{ id: "coin-modal", title: "" }}
          handleClose={() => openModalImage(false)}
          maxWidthDialog="md">
          <img
            alt="portrait"
            className="modal-image"
            src={documents ? documents.url : defaultImg}
          />
        </ModalCustom>
      ) : (
        ""
      )}
    </div>
  );
});
export default withRouter(UnEmployeeDetail);
