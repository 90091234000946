export const COMMON_GET_USER_INFOR = "COMMON_GET_USER_INFOR";
export const COMMON_GET_USER_INFOR_SUCCESS = "COMMON_GET_USER_INFOR_SUCCESS";
export const COMMON_SHOW_LOADING = "COMMON_SHOW_LOADING";
export const COMMON_HIDE_LOADING = "COMMON_HIDE_LOADING";
export const COMMON_HANDLE_ERROR = "COMMON_HANDLE_ERROR";
export const COMMON_SET_SERVER_ERROR = "COMMON_SET_SERVER_ERROR";
export const COMMON_GET_LIST_CONTRACT_TYPE = "COMMON_GET_LIST_CONTRACT_TYPE";
export const COMMON_GET_LIST_CONTRACT_TYPE_SUCCESS =
  "COMMON_GET_LIST_CONTRACT_TYPE_SUCCESS";
export const COMMON_SET_CLAIMED_COIN = "COMMON_SET_CLAIMED_COIN";
export const COMMON_GET_NOTIFICATIONS = "COMMON_GET_NOTIFICATIONS";
export const COMMON_GET_NOTIFICATIONS_SUCCESS =
  "COMMON_GET_NOTIFICATIONS_SUCCESS";
