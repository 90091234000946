import { put, takeLatest } from "redux-saga/effects";
import { setLocalStore, removeLocalStore } from "../../utils/localStore";
import { push } from "connected-react-router";
import { reduxAction } from "../../store/action";
import requestApi from "../../apis/requestApi";
import {
  COMMON_SHOW_LOADING,
  COMMON_HIDE_LOADING,
  COMMON_HANDLE_ERROR,
  COMMON_GET_USER_INFOR_SUCCESS
} from "../../store/constants";
import { CONFIRM_SET_TAB_ACTIVE } from "../Confirm/constants";
function* loginSaga(action) {
  try {
    yield put({
      type: COMMON_SHOW_LOADING
    });
    let user = action.payload;
    user.phone = "0" + user.phone;
    const res = yield requestApi.post("/auth/sign_in", action.payload);
    const { data, headers } = res;

    if (data && data.personal_info) {
      setLocalStore(headers);
      data.isLogin = true;
      yield put(reduxAction(COMMON_GET_USER_INFOR_SUCCESS, data));
      yield put(push("/profile/confirm-list"));
    }
  } catch (err) {
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put({
      type: COMMON_HIDE_LOADING
    });
  }
}
function* logoutSaga() {
  try {
    yield put({
      type: COMMON_SHOW_LOADING
    });
    const data = yield requestApi.delete("/auth/sign_out");
    if (data) {
      yield removeLocalStore();
      yield put(push("/login"));
      yield put(reduxAction(CONFIRM_SET_TAB_ACTIVE, 1));
    }
  } catch (err) {
    yield put(reduxAction(COMMON_HANDLE_ERROR, err));
  } finally {
    yield put({
      type: COMMON_HIDE_LOADING
    });
  }
}

export function* watchLogin() {
  yield takeLatest("LOGIN", loginSaga);
  yield takeLatest("LOGOUT", logoutSaga);
}
