import React from "react";
import Logo from "./logo";
import DropDownMenu from "../DropDownMenu";
import HeaderList from "../HeaderList";
import "./style.scss";
import { useSelector } from "react-redux";
import { getLoginInfoSelector } from "../../store/selectors";
const Header = React.memo(props => {
  const userInfo = useSelector(getLoginInfoSelector);
  const {
    full_name,
    documents,
    coin,
    total_unverified_employee,
    company_logo
  } = userInfo.personal_info || {};
  return (
    <div className="header">
      <div className="header-main container">
        <Logo />
        <div className="header-barge">
          <HeaderList
            coin={coin}
            fullName={full_name}
            isMobile={false}
            unverifiedEmployee={total_unverified_employee}
          />
          <DropDownMenu
            fullName={full_name}
            imgData={documents}
            coin={coin}
            companyLogo={company_logo}
            unverifiedEmployee={total_unverified_employee}
          />
          {/* <NotificationComponent /> */}
        </div>
      </div>
    </div>
  );
});

export default Header;
