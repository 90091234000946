import React, { useRef } from "react";
import "./style.scss";
import cameraIcon from "../../assets/images/camera.png";
const UploadComponent = React.memo(props => {
  const inputEl = useRef(null);
  const {
    type,
    className,
    files,
    notShowPicture,
    name,
    alts,
    helperText
  } = props;
  const handleUpload = event => {
    props.handleUpload({
      name: name,
      data: event
    });
  };
  const handleEdit = () => {
    inputEl.current.click();
  };
  const renderInner = type => {
    const {
      txtBtn,
      txtBtnUpdate,
      files,
      name,
      hasUpdate,
      notShowPicture,
      hasDelete
    } = props;
    switch (type) {
      case "img":
        return (
          <React.Fragment>
            <img
              src={cameraIcon}
              alt="uploadfile"
              className={`upload__thumb`}
            />
            <img
              src={files}
              className="upload__thumb-success"
              id={`img-thumb-${name}`}
              alt="uploadfile"
            />
            {hasUpdate && files && (
              <button type="button" className="upload__btn-update">
                {"Cập nhật"}
              </button>
            )}
            <input
              type="file"
              name={name || "uploadfile"}
              onChange={handleUpload}
              className="upload__input"
              // multiple="multiple"
              accept="image/*"
            />
          </React.Fragment>
        );
      case "onlyShow":
        return (
          <React.Fragment>
            <img
              src={cameraIcon}
              alt="uploadfile"
              className={`upload__thumb`}
            />
            {files ? (
              <img
                src={files}
                className="upload__thumb-success"
                id={`img-thumb-${name}`}
                alt={alts}
              />
            ) : (
              ""
            )}
            {!hasDelete && (
              <button
                type="button"
                className="upload__btn-update upload__btn-delete"
                onClick={handleEdit}>
                {txtBtnUpdate || "Xoá"}
              </button>
            )}
            <input
              type="file"
              name={name || "uploadfile"}
              onChange={handleUpload}
              className="upload__input"
              ref={inputEl}
              // multiple="multiple"
              accept="file_extension"
            />
          </React.Fragment>
        );
      case "onlyShowImg":
        return (
          <React.Fragment>
            <img
              src={cameraIcon}
              alt="uploadfile"
              className={`upload__thumb`}
            />
            {files && !notShowPicture && (
              <img
                src={files}
                className="upload__thumb-success"
                id={`img-thumb-${name}`}
                alt="uploadfile"
              />
            )}
            <button
              type="button"
              className="upload__btn-update upload__btn-delete"
              onClick={handleUpload}>
              {txtBtnUpdate || "Xoá"}
            </button>
            <input
              type="file"
              name={name || "uploadfile"}
              onChange={handleUpload}
              className="upload__input"
              // multiple="multiple"
              accept="image/*"
            />
          </React.Fragment>
        );
      default:
        return (
          <React.Fragment>
            <button type="button" className="upload__btn">
              {txtBtn || "Chọn tập tin"}
            </button>
            <input
              type="file"
              name={name || "uploadfile"}
              onChange={handleUpload}
              className="upload__input"
              multiple="multiple"
              accept="file_extension|audio/*|video/*|image/*|media_type"
            />
          </React.Fragment>
        );
    }
  };
  return (
    <>
      <div
        className={`upload ${className ? className : ""} ${
          files ? "upload--loading" : ""
        } ${notShowPicture ? "upload--loading" : ""}`}>
        <div className="upload__inner">{renderInner(type)}</div>
      </div>
      {helperText ? <p className="feild-error">{helperText}</p> : ""}
    </>
  );
});

export default UploadComponent;
