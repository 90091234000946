import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../components/Router/PrivateRoute";
import AppLayout from "../components/AppLayout";
import ConfirmRouter from "./confirm";
import Information from "../containers/Information";

const ProfileRouter = () => {
  return (
    <Switch>
      <AppLayout>
        {/* <Switch> */}
        <PrivateRoute exact path="/profile" component={Information} />
        <PrivateRoute path="/profile/confirm-list" component={ConfirmRouter} />
        <PrivateRoute
          exact
          path="/profile/information"
          component={Information}
        />
        {/* </Switch> */}
      </AppLayout>
    </Switch>
  );
};

export default ProfileRouter;
