import { useState, useEffect } from "react";
import { isDulicateFile } from "../utils/validator/isDulicateFile";
import isEqual from "lodash/isEqual";
const useForm = (initState, callBack, error_schema, config) => {
  const [values, setValues] = useState(initState);
  const [errors, setErrors] = useState({});
  const [isSubmiting, setIsSubmiting] = useState(false);
  const { isSameOrigin } = config;
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmiting) {
      callBack();
    }
    return () => {
      setIsSubmiting(false);
    };
  }, [isSubmiting]);

  const handleChange = events => {
    let el = events.target;
    let name = el.name;
    let value = el.value;
    if (name === "monthly_income") {
      value = value.replace(/\./g, "");
    }
    delete errors[name];
    delete errors["isSameOrigin"];
    setValues(values => ({
      ...values,
      [name]: value
    }));
  };

  const handleSelect = events => {
    let el = events.target;
    let name = el.name;
    let value = el.value;
    events.persist();
    delete errors[name];
    delete errors["isSameOrigin"];
    setValues(values => ({
      ...values,
      [name]: {
        ...values[name],
        id: String(value),
        value: String(value)
      }
    }));
  };
  const handleUpload = events => {
    const { name, data } = events;
    const files = data.target.files[0];
    const reader = new FileReader();
    if (files) {
      reader.readAsDataURL(files);
    }
    delete errors[name];
    delete errors["isSameOrigin"];
    const isValiDulicateFile = isDulicateFile(files, values.documents);
    if (!isValiDulicateFile) {
      reader.onloadend = () => {
        setValues(values => ({
          ...values,
          [name]: {
            data: files,
            url: reader.result
          }
        }));
      };
    } else {
      setErrors(error => ({
        ...error,
        [name]: isValiDulicateFile
      }));
    }
  };
  const handleSubmit = events => {
    let error = {};
    events.preventDefault();
    if (isSameOrigin && isEqual(initState, values)) {
      setErrors(error => ({
        ...error,
        isSameOrigin: "Vui lòng thay đổi thông tin cần chỉnh sửa"
      }));
    } else {
      Object.keys(error_schema).map(item => {
        if (error_schema[item] && error_schema[item].messenge) {
          error[item] = error_schema[item].messenge;
        }
        if (!error_schema[item].messenge) {
          delete error[item];
        }
        return item;
      });
      setErrors(error);
      setIsSubmiting(true);
    }
  };

  return {
    handleChange,
    handleSubmit,
    handleSelect,
    handleUpload,
    values,
    errors
  };
};
export default useForm;
