import produce from "immer";
import {
  CONFIRM_GET_LIST_CONFIRM_SUCCESS,
  CONFIRM_SET_PAGE_CONFIRM,
  CONFIRM_SET_ROWS_PER_PAGE_CONFIRM,
  CONFIRM_SET_ORDER_CONFIRM,
  CONFIRM_SET_KEYWORD_CONFIRM,
  CONFIRM_GET_LIST_UNCONFIRM_SUCCESS,
  CONFIRM_SET_PAGE_UNCONFIRM,
  CONFIRM_SET_ROWS_PER_PAGE_UNCONFIRM,
  CONFIRM_SET_ORDER_UNCONFIRM,
  CONFIRM_SET_KEYWORD_UNCONFIRM,
  CONFIRM_SET_TAB_ACTIVE
} from "./constants";

export const initialState = {
  confirm: {
    data: [],
    total_count: 0,
    page: 1,
    rowsPerPage: 5,
    order: "desc",
    orderBy: "verified_at",
    keyword: ""
  },
  unconfirm: {
    data: [],
    total_count: 0,
    page: 1,
    rowsPerPage: 5,
    order: "desc",
    orderBy: "created_at",
    keyword: ""
  },
  tabActive: "1"
};
const confirmReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CONFIRM_GET_LIST_CONFIRM_SUCCESS:
        draft.confirm.data = action.payload.data;
        draft.confirm.total_count = action.payload.meta.total_count;
        break;
      case CONFIRM_SET_PAGE_CONFIRM:
        draft.confirm.page = action.payload;
        break;
      case CONFIRM_SET_ROWS_PER_PAGE_CONFIRM:
        draft.confirm.rowsPerPage = action.payload;
        break;
      case CONFIRM_SET_ORDER_CONFIRM:
        draft.confirm.order = action.payload.order;
        draft.confirm.orderBy = action.payload.orderBy;
        break;
      case CONFIRM_SET_KEYWORD_CONFIRM:
        draft.confirm.keyword = action.payload;
        break;
      //unconfirm
      case CONFIRM_GET_LIST_UNCONFIRM_SUCCESS:
        draft.unconfirm.data = action.payload.data;
        draft.unconfirm.total_count = action.payload.meta.total_count;
        break;
      case CONFIRM_SET_PAGE_UNCONFIRM:
        draft.unconfirm.page = action.payload;
        break;
      case CONFIRM_SET_ROWS_PER_PAGE_UNCONFIRM:
        draft.unconfirm.rowsPerPage = action.payload;
        break;
      case CONFIRM_SET_ORDER_UNCONFIRM:
        draft.unconfirm.order = action.payload.order;
        draft.unconfirm.orderBy = action.payload.orderBy;
        break;
      case CONFIRM_SET_KEYWORD_UNCONFIRM:
        draft.unconfirm.keyword = action.payload;
        break;
      case CONFIRM_SET_TAB_ACTIVE:
        draft.tabActive = action.payload;
        break;
      default:
        return state;
    }
  });
export default confirmReducer;
