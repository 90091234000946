import { createSelector } from "reselect";

const makeSelectConfirmList = createSelector(
  state => state.confirmReducer.confirm.data,
  data => data
);
const makeSelectConfirmTotal = createSelector(
  state => state.confirmReducer.confirm.total_count,
  total_count => total_count
);
const makeSelectConfirmPage = createSelector(
  state => state.confirmReducer.confirm.page,
  page => page
);

const makeSelectConfirmRowsPerPage = createSelector(
  state => state.confirmReducer.confirm.rowsPerPage,
  rowsPerPage => rowsPerPage
);

const makeSelectConfirmKeyword = createSelector(
  state => state.confirmReducer.confirm.keyword,
  keyword => keyword
);

const makeSelectConfirmOrder = createSelector(
  state => state.confirmReducer.confirm,
  page => ({ order: page.order, orderBy: page.orderBy })
);
//
const makeSelectUnconfirmList = createSelector(
  state => state.confirmReducer.unconfirm.data,
  data => data
);
const makeSelectUnconfirmTotal = createSelector(
  state => state.confirmReducer.unconfirm.total_count,
  total_count => total_count
);
const makeSelectUnconfirmPage = createSelector(
  state => state.confirmReducer.unconfirm.page,
  page => page
);

const makeSelectUnconfirmRowsPerPage = createSelector(
  state => state.confirmReducer.unconfirm.rowsPerPage,
  rowsPerPage => rowsPerPage
);

const makeSelectUnconfirmKeyword = createSelector(
  state => state.confirmReducer.unconfirm.keyword,
  keyword => keyword
);

const makeSelectUnconfirmOrder = createSelector(
  state => state.confirmReducer.unconfirm,
  page => ({ order: page.order, orderBy: page.orderBy })
);

const makeSelectTabActive = createSelector(
  state => state.confirmReducer.tabActive,
  tabActive => parseInt(tabActive)
);

export {
  makeSelectConfirmTotal,
  makeSelectConfirmPage,
  makeSelectConfirmList,
  makeSelectConfirmRowsPerPage,
  makeSelectConfirmOrder,
  makeSelectConfirmKeyword,
  makeSelectUnconfirmList,
  makeSelectUnconfirmTotal,
  makeSelectUnconfirmPage,
  makeSelectUnconfirmRowsPerPage,
  makeSelectUnconfirmKeyword,
  makeSelectUnconfirmOrder,
  makeSelectTabActive
};
