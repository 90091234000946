import React from "react";
// import "./style.scss";
const HeaderListItem = React.memo(props => {
  const { name, data, clssName, onClick } = props || "";
  return (
    <div
      className={
        clssName ? "header-list__item " + clssName : "header-list__item"
      }
      onClick={onClick}>
      <span className="header-list__name">{name}</span>
      <span className="header-list__label">{data}</span>
    </div>
  );
});
export default HeaderListItem;
