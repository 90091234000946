import produce from "immer";
import get from "lodash.get";
export const initialState = {};
const loginReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case "GET_COMPANIES_SUCCESS":
        const { data } = action.payload;
        draft.companies = data;
        draft.companySelected = get(data, ["0"], null);
        break;
      default:
        return state;
    }
  });
export default loginReducer;
